import React from 'react';

const ErrorPage = () => {
  return (
    <div>
      <h1>Unexpected Application Error!</h1>
      <p>We're sorry, but something went wrong.</p>
      {/* Add more information or user-friendly error message here */}
    </div>
  );
};

export default ErrorPage;