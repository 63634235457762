import React from 'react'

const AppFooter = () => {
  return (<>
  
    </>
  )
}

export default React.memo(AppFooter)
